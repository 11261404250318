import { FC } from 'react'

import { withPage } from '~/components/page'
import SignUpView from '~/views/signUp'

const SignUp: FC = () => {
	return <SignUpView />
}

export default withPage(SignUp, {
	public: true,
	name: 'Sign Up',
})

import { useMutation, gql } from '@apollo/client'
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Heading,
	Text,
	useColorModeValue,
	Link,
	FormErrorMessage,
} from '@chakra-ui/react'
import { navigate, Link as GatsbyLink } from 'gatsby'
import { useForm } from 'react-hook-form'

import { FC } from 'react'

import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'

import { SignUpUser } from './__generated__/SignUpUser'

/***
 *
 * Signup Query
 *
 ***/
const signUpUser = gql`
	mutation SignUpUser($userData: UsersPermissionsRegisterInput!) {
		register(input: $userData) {
			user {
				id
				email
			}
		}
	}
`

/***
 *
 * Singup View Component
 *
 ***/
interface FormProps {
	username: string
	email: string
	password: string
}

const SignUpView: FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<FormProps>()

	const [signUp, { loading }] = useMutation<SignUpUser>(signUpUser, {
		onError: error => onError(error, toast),
	})
	const toast = useToast()

	const submitHandler = async (formData: FormProps) => {
		const { errors } = await signUp({ variables: { userData: formData } })
		!errors && navigate('/login')
	}

	return (
		<Flex minH='100vh' justify='center' bg={useColorModeValue('gray.100', 'gray.800')} pt={12}>
			<Stack spacing={8} mx='auto' minW='md' py={12} px={6}>
				<Stack align='center'>
					<Heading fontSize='4xl' textAlign='center'>
						Sign up
					</Heading>
				</Stack>
				<Box rounded='lg' bg={useColorModeValue('white', 'gray.700')} boxShadow='lg' p={8}>
					<Stack as='form' spacing={4} onSubmit={handleSubmit(submitHandler)}>
						<FormControl id='username' isInvalid={!!errors?.username}>
							<FormLabel>Username</FormLabel>
							<Input {...register('username', { required: true })} />
							{!!errors?.username && <FormErrorMessage>Username is required</FormErrorMessage>}
						</FormControl>
						<FormControl id='email' isInvalid={!!errors?.email}>
							<FormLabel>Email address</FormLabel>
							<Input type='email' {...register('email', { required: true })} />
							{!!errors?.email && <FormErrorMessage>Email is required</FormErrorMessage>}
						</FormControl>
						<FormControl id='password' isInvalid={!!errors?.password}>
							<FormLabel>Password</FormLabel>
							<Input type='password' {...register('password', { required: true })} />
							{!!errors?.password && <FormErrorMessage>Password is required</FormErrorMessage>}
						</FormControl>
						<Stack spacing={10} pt={2}>
							<Button
								isLoading={isSubmitting || loading}
								size='lg'
								bg='gray.800'
								color='primary'
								_hover={{
									bg: 'gray.800',
								}}
								type='submit'
							>
								Sign up
							</Button>
						</Stack>
						<Stack pt={6}>
							<Text align='center'>
								Already a user?
								<Link to='/login' as={GatsbyLink} color='blue.400' ml='0.5em'>
									Login
								</Link>
							</Text>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	)
}

export default SignUpView
